import axios from "axios";

const API_KEY = process.env.REACT_APP_APIKEY;
const BASE_URL = process.env.REACT_APP_BASEURL;

export const getMovielist = async () => {
    const movie = await axios.get(`${BASE_URL}/movie/popular?page=1&api_key=${API_KEY}`)
    return movie.data.results;
}

export const searchMovie = async (q) => {
    const search = await axios.get(`${BASE_URL}/search/movie?query=${q}&page=1&api_key=${API_KEY}`)
    return search.data.results;
}